<template>
  <div class="content_body" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
            <el-form-item label="会员来源名称">
              <el-input v-model="Name" placeholder="输入会员来源名称搜索" clearable @clear="handleSearch"></el-input>
            </el-form-item>
            <el-form-item label="有效性">
              <el-select v-model="Active" placeholder="请选择有效性" clearable @change="handleSearch">
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" size="small" @click="showAddDialog" v-prevent-click>新增</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="martp_10">
      <el-table
        :data="customerSourceTableData"
        size="small"
        tooltip-effect="light"
        row-key="ID"
        :tree-props="{children: 'Child', hasChildren: 'hasChildren'}"
      >
        <el-table-column prop="Name" label="来源名称"></el-table-column>
        <el-table-column label="移动" min-width="180px">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="primary"
              circle
              icon="el-icon-upload2"
              @click="upOneClick(scope.row,scope.$index)"
            ></el-button>
            <el-button
              size="small"
              type="primary"
              circle
              icon="el-icon-top"
              @click="upClick(scope.row,scope.$index)"
            ></el-button>
            <el-button
              size="small"
              type="primary"
              circle
              icon="el-icon-bottom"
              @click="downClick(scope.row,scope.$index)"
            ></el-button>
            <el-button
              size="small"
              type="primary"
              circle
              icon="el-icon-download"
              @click="downOneClick(scope.row,scope.$index)"
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column label="有效性">
          <template slot-scope="scope">{{scope.row.Active?'是':'否'}}</template>
        </el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="showEditDialog(scope.row)"
              v-prevent-click
            >编辑</el-button>
            <el-button
              type="primary"
              size="small"
              @click="addSubClassification(scope.row)"
              v-if="scope.row.ParentID == 0 && scope.row.Active"
              v-prevent-click
            >添加子来源</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--编辑、新增弹框-->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        class="demo-ruleForm"
        size="small"
      >
        <el-form-item
          label="上级来源"
          v-if="ruleForm.parentName !='' && ruleForm.parentName != undefined"
        >
          <el-input v-model="ruleForm.parentName" :disabled="true">></el-input>
        </el-form-item>
        <el-form-item label="来源名称" prop="Name" v-if="whichDialog == '0'||whichDialog == '1'">
          <el-input v-model="ruleForm.Name" placeholder="请输入来源名称"></el-input>
        </el-form-item>
        <el-form-item label="来源名称" prop="classificationName" v-if="whichDialog == '2'">
          <el-input v-model="ruleForm.classificationName" placeholder="请输入分类名称"></el-input>
        </el-form-item>
        <el-form-item label="是否有效" prop="Active" v-if="whichDialog == '1'">
          <el-radio-group v-model="ruleForm.Active">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small" v-prevent-click>取 消</el-button>
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          @click="submitForm('ruleForm')"
          v-prevent-click
        >保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/CRM/Customer/customerSource";

export default {
  name: "CustomerSource",
  data() {
    return {
      loading: false,
      modalLoading: false,
      Name: "", // 搜索框值
      Active: true, // 有效性
      customerSourceTableData: [], // 顾客来源表格数据
      dialogVisible: false, // 编辑、新增弹出框状态
      whichDialog: "", // 判断弹出框类别
      ParentID: "", // 父级ID
      NameParams: "", // 新增所传参数
      dialogTitle: "", // 弹框标题
      moveId: "", // 移动ID
      moveIndex: "",
      inputType: "",
      ruleForm: {
        Name: "", // 来源名称
        Active: true, // 是否有效
        ID: "", // 来源ID
        classificationName: "" // 分类名称
      }, // 编辑、新增表单数据
      rules: {
        Name: [{ required: true, message: "请输入来源名称", trigger: "blur" }],
        Active: [
          { required: true, message: "请选择是否有效", trigger: "change" }
        ],
        classificationName: [
          {
            required: true,
            message: "请输入来源名称",
            trigger: "change"
          }
        ]
      } // 编辑、新增表单规则验证
    };
  },
  methods: {
    // 搜索
    handleSearch() {
      var that = this;
      that.getCustomerSource();
    },
    // 获取顾客来源列表
    getCustomerSource() {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.Name,
        Active: that.Active
      };
      API.getCustomerSource(params)
        .then(res => {
          if (res.StateCode == 200) {
            that.customerSourceTableData = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 新增按钮点击事件
    showAddDialog() {
      var that = this;
      that.whichDialog = "0";
      that.ruleForm = {};
      that.ParentID = "";
      that.inputType = "来源名称";
      that.dialogTitle = "新增会员来源";
      that.dialogVisible = true;
    },
    // 编辑
    showEditDialog(row) {
      var that = this;
      that.whichDialog = "1";
      that.inputType = "来源名称";
      that.dialogTitle = "编辑会员来源";
      that.ruleForm = Object.assign({}, row);
      that.customerSourceTableData.forEach(val => {
        if (row.ParentID == val.ID) {
          that.ruleForm.parentName = val.Name;
        }
      });
      that.dialogVisible = true;
    },
    // 编辑、新增、添加子分类 保存按钮点击事件
    submitForm(formName) {
      var that = this;
      that.$refs[formName].validate(valid => {
        if (valid) {
          if (that.whichDialog == "0") {
            that.NameParams = that.ruleForm.Name;
            that.createCustomerSource();
          } else if (that.whichDialog == "1") {
            that.updateCustomerSource();
          } else {
            that.NameParams = that.ruleForm.classificationName;
            that.createCustomerSource();
          }
        }
      });
    },
    // 新增顾客来源
    createCustomerSource() {
      var that = this;
      that.modalLoading = true;
      var params = {
        ParentID: that.ParentID,
        Name: that.NameParams
      };
      API.createCustomerSource(params)
        .then(res => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getCustomerSource();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000
            });
          }
        })
        .finally(function() {
          that.modalLoading = false;
        });
    },
    // 修改来源
    updateCustomerSource() {
      var that = this;
      that.modalLoading = true;
      var params = {
        ID: that.ruleForm.ID,
        Name: that.ruleForm.Name,
        Active: that.ruleForm.Active
      };
      API.updateCustomerSource(params)
        .then(res => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getCustomerSource();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000
            });
          }
        })
        .finally(function() {
          that.modalLoading = false;
        });
    },
    // 添加子分类点击事件
    addSubClassification(row) {
      var that = this;
      that.ParentID = row.ID;
      that.whichDialog = "2";
      that.ruleForm = {};
      that.inputType = "分类名称";
      that.dialogTitle = "顾客来源-添加子分类";
      if (row.ParentID == 0) {
        that.ruleForm.parentName = row.Name;
      }
      that.dialogVisible = true;
    },
    // 递归
    setRecursion(data) {
      var that = this;
      var index = data.length;
      var moveId = "",
        beforeId = "";
      for (let i = 0; i <= data.length - 1; i++) {
        if (that.moveId == data[i].ID) {
          if (that.moveIndex == 0) {
            moveId = data[i].ID;
            beforeId = "";
          } else if (that.moveIndex == 1) {
            moveId = data[i].ID;
            if (i == 0 || i == 1) {
              beforeId = "";
            } else {
              beforeId = data[i - 2].ID;
            }
          } else if (that.moveIndex == 2) {
            moveId = data[i].ID;
            if (i == index - 1) {
              beforeId = data[i - 1].ID;
            } else {
              beforeId = data[i + 1].ID;
            }
          } else {
            moveId = data[i].ID;
            if (i == index - 1) {
              beforeId = data[i - 1].ID;
            } else {
              beforeId = data[index - 1].ID;
            }
          }
        }
      }
      that.moveCustomerSource(moveId, beforeId);
    },
    // 移动至顶部
    upOneClick(row) {
      var that = this;
      that.moveIndex = 0;
      that.moveId = row.ID;
      var customerSourceList = [];
      if (row.ParentID == 0) {
        that.setRecursion(that.customerSourceTableData);
      } else {
        that.customerSourceTableData.forEach(val => {
          if (val.ID == row.ParentID) {
            customerSourceList = val.Child;
          }
        });
        that.setRecursion(customerSourceList);
      }
    },
    // 向上移动
    upClick(row) {
      var that = this;
      that.moveId = row.ID;
      that.moveIndex = 1;
      var customerSourceList = [];
      if (row.ParentID == 0) {
        that.setRecursion(that.customerSourceTableData);
      } else {
        that.customerSourceTableData.forEach(val => {
          if (val.ID == row.ParentID) {
            customerSourceList = val.Child;
          }
        });
        that.setRecursion(customerSourceList);
      }
    },
    // 向下移动
    downClick(row) {
      var that = this;
      that.moveId = row.ID;
      that.moveIndex = 2;
      var customerSourceList = [];
      if (row.ParentID != 0) {
        that.customerSourceTableData.forEach(val => {
          if (val.ID == row.ParentID) {
            customerSourceList = val.Child;
          }
        });
        that.setRecursion(customerSourceList);
      } else {
        that.setRecursion(that.customerSourceTableData);
      }
    },
    // 移动至底部
    downOneClick(row) {
      var that = this;
      that.moveId = row.ID;
      that.moveIndex = 3;
      var customerSourceList = [];
      if (row.ParentID == 0) {
        that.setRecursion(that.customerSourceTableData);
      } else {
        that.customerSourceTableData.forEach(val => {
          if (val.ID == row.ParentID) {
            customerSourceList = val.Child;
          }
        });
        that.setRecursion(customerSourceList);
      }
    },
    // 移动顾客来源
    moveCustomerSource(MoveID, BeforeID) {
      var that = this;
      that.loading = true;
      var params = {
        MoveID: MoveID,
        BeforeID: BeforeID
      };
      API.moveCustomerSource(params)
        .then(res => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.getCustomerSource();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    }
  },
  mounted() {
    var that = this;
    that.getCustomerSource();
  }
};
</script>

<style scoped>
</style>